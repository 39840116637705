import { createStore } from 'vuex'

// 创建一个新的 store 实例
const store = createStore({
    state() {
        return {
            isHasAuth: false
        }
    },
    mutations: {
        setIsHasAuth(state, value) {
            state.isHasAuth = value
        }
    }
})
export default store