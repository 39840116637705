<template>
    <!-- <Wrapper style="height: 100%;width: 100%;background-color: #ddd;">
        <div ref="canvasContainer" id="canvasContainer"></div>
    </Wrapper> -->
    <div v-loading.fullscreen.lock="fullscreenLoading"
        style="width:100%;display: flex;justify-content: center;background-color: #ddd;height: 100vh;overflow-y: auto;">
        <div class="pdf-wrap" :style="{ 'width': pdfWidth }">
            <!-- <Wrapper style="height: 100%;width: 100%;"> -->
            <vue-pdf-embed v-for="item in state.numPages" :source="state.source" :style="scale" class="vue-pdf-embed"
                :page="item" />
            <!-- </Wrapper> -->
            <!-- <vue-pdf-embed :source="state.source" :style="scale" class="vue-pdf-embed" :page="2" /> -->
        </div>
    </div>
</template>

<script setup>
import { onMounted, ref, reactive, nextTick } from 'vue';
import { onBeforeRouteLeave, useRouter } from "vue-router"
import VuePdfEmbed from "vue-pdf-embed";
import { createLoadingTask } from "vue3-pdfjs/esm";
const fullscreenLoading = ref(true)
const router = useRouter()
const state = reactive({
    source: router.currentRoute.value.query.pdfUrl,
    scale: 1, // 缩放比例
    numPages: 0, // 总页数
})
//获取页面缩放数据
const viewport = document.getElementById('viewport')
//windows系统宽度与手机和平板宽度分离
const pdfWidth = ref((window.navigator.userAgent.includes('Windows') ? '800px' : window.innerWidth + 'px'))
onMounted(() => {
    const loadingTask = createLoadingTask(state.source);
    loadingTask.promise.then((pdf) => {
        state.numPages = pdf.numPages;
    });
    fullscreenLoading.value = false

    //允许页面缩放
    viewport.content = 'width=device-width, initial-scale=1'
})
//页面离开时，恢复不可缩放
onBeforeRouteLeave(() => {
    viewport.content = "width=device-width,initial-scale=1.0, maximum-scale=1.0, user-scalable=0;"
})

</script>
