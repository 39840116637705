import { createRouter, createWebHashHistory } from 'vue-router'
import pdfView from "../views/pdfView/PDFView.vue"
const routes = [
  {
    path: '/',
    redirect: "/login"
  },
  {
    path: "/pdfView",
    name: "pdfView",
    component: pdfView
  },
  {
    path: "/3DRender",
    name: "3DRender",
    component: () => import("../views/3DRender/3DRender.vue")
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/login/Login.vue')
  },
  {
    path: '/home',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/home/Home.vue'),
    children: [
      {
        path: "/home",
        redirect: "/home/userManagement"
      },
      {
        path: "/home/administratorSettings",
        name: "administratorSettings",
        component: () => import("../views/home/administratorSettings/AdministratorSettings.vue")
      },
      {
        path: "/home/userManagement",
        name: "userManagement",
        component: () => import("../views/home/userManagement/UserManagement.vue")
      },
      {
        path: "/home/modelConfiguration",
        name: "modelConfiguration",
        component: () => import("../views/home/modelConfiguration/ModelConfiguration.vue")
      }
      ,
      {
        path: "/home/sort",
        name: "sort",
        component: () => import("../views/home/sort/Sort.vue")
      }
    ]
  },
  {
    path: "/404NotFound",
    name: "404NotFound",
    component: () => import("../views/404NotFound/404NotFound.vue")
  }
]


const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.path == "/login") {
    window.sessionStorage.removeItem("roleId")
    return next()
  } else if (to.path == "/404NotFound") {
    return next()
  }
  //没有登录
  // if (!window.sessionStorage.getItem("roleId")) return next("/login")
  //登录成功

  if (to.path.startsWith("/home")) {
    if (window.sessionStorage.getItem("roleId") == '0') {
      return next()
    }
  } else if (to.path == "/3DRender") {
    return next()
  }
  else if (to.path == "/pdfView") {
    return next()
  }
  return next("/404NotFound")
})

export default router
